import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import axios from '../../utils/axios';
import { formatDateWithoutTime } from '../../utils/formatDateWithoutTime';
const initialState = {
    AnnualExpense: 0,
    AnnualRevenue: 0,
    totalReservations: 0,
    totalCustomers: 0,
    totalBranches: 0,
    reservationClassMetrics: [],
    totalRentals: 0,
    availabilityReport: [],
    incomesAndOutcomes: {
        categories: [{ classId: null, data: [] }],
        series: [
            {
                classId: null,
                name: '',
                data: [
                    {
                        name: '',
                        data: [],
                    },
                ],
            },
        ],
    },
    ReservationByPricingGroup: [],
    ReservationByClass: [],
    DailyIncomeByBranch: [],
    InAndOutByYear: [],
    PickupAndReturnRentable: [],
    years: [],
    AnnualExpenseStatus: 'idle',
    AnnualRevenueStatus: 'idle',
    availabilityReportStatus: 'idle',
    incomesAndOutcomesStatus: 'idle',
    ReservationByPricingGroupStatus: 'idle',
    ReservationByClassStatus: 'idle',
    DailyIncomeByBranchStatus: 'idle',
    InAndOutByYearStatus: 'idle',
    PickupAndReturnRentableStatus: 'idle',
    PickupAndReturnRentableByDaysStatus: 'idle',
    yearsStatus: 'idle',
    totalReservationsStatus: 'idle',
    totalRentalsStatus: 'idle',
    totalBranchesStatus: 'idle',
    totalCustomersStatus: 'idle',
    reservationClassMetricsStatus: 'idle',
    dailyTasksStatus: 'idle',
    dailyTasks: { todayPickup: 0, todayReturn: 0, expenses: [] },
};
export const getAnnualExpense = createAsyncThunk('statistic/getAnnualExpense', async () => {
    let data;
    try {
        const response = await axios.get(`/dashboard/annualExpense`);
        data = await response.data;
        if (response.status === 200) {
            return data?.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getAnnualRevenue = createAsyncThunk('statistic/getAnnualRevenue', async () => {
    let data;
    try {
        const response = await axios.get(`/dashboard/annualRevenue`);
        data = await response.data;
        if (response.status === 200) {
            return data?.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getAvailabilityReport = createAsyncThunk('statistic/getAvailabilityReport', async () => {
    let data;
    try {
        const response = await axios.get(`/dashboard/availabilityReport`);
        data = await response.data;
        if (response.status === 200) {
            return data?.data?.series;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getRentableIncomesAndOutcomes = createAsyncThunk('statistic/getRentableIncomesAndOutcomes', async ({ values, isSameDay }) => {
    let data;
    try {
        const response = await axios.post(`/dashboard/incomesAndOutcomesForRentable`, {
            ...values,
            startDate: isSameDay
                ? dayjs(values.startDate).hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm')
                : formatDateWithoutTime(values.startDate),
            endDate: isSameDay
                ? dayjs(values.endDate).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm')
                : formatDateWithoutTime(values.endDate),
        });
        data = await response.data;
        if (response.status === 200) {
            return data?.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getRentableIncomesAndOutcomesByDays = createAsyncThunk('statistic/getRentableIncomesAndOutcomesByDays', async ({ values, isSameDay }) => {
    let data;
    try {
        const response = await axios.post(`/dashboard/incomesAndOutcomesForRentableByDays`, {
            ...values,
            startDate: isSameDay
                ? dayjs(values.startDate).hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm')
                : formatDateWithoutTime(values.startDate),
            endDate: isSameDay
                ? dayjs(values.endDate).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm')
                : formatDateWithoutTime(values.endDate),
        });
        data = await response.data;
        if (response.status === 200) {
            return data?.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const incomesAndOutcomesByYear = createAsyncThunk('statistic/incomesAndOutcomesByYear', async ({ year }) => {
    let data;
    try {
        const response = await axios.post(`/dashboard/incomesAndOutcomesByYear`, { year });
        data = await response.data;
        if (response.status === 200) {
            return data?.data?.series;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getReservationByPricingGroup = createAsyncThunk('statistic/getReservationByPricingGroup', async ({ values, isSameDay }) => {
    let data;
    try {
        const response = await axios.post(`/dashboard/reservationByPricingGroup`, {
            ...values,
            startDate: isSameDay
                ? dayjs(values.startDate).hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm')
                : formatDateWithoutTime(values.startDate),
            endDate: isSameDay
                ? dayjs(values.endDate).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm')
                : formatDateWithoutTime(values.endDate),
        });
        data = await response.data;
        if (response.status === 200) {
            return data?.data?.series;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getReservationByClass = createAsyncThunk('statistic/getReservationByClass', async ({ values, isSameDay }) => {
    let data;
    try {
        const response = await axios.post(`/dashboard/reservationByClass`, {
            ...values,
            startDate: isSameDay
                ? dayjs(values.startDate).hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm')
                : formatDateWithoutTime(values.startDate),
            endDate: isSameDay
                ? dayjs(values.endDate).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm')
                : formatDateWithoutTime(values.endDate),
        });
        data = await response.data;
        if (response.status === 200) {
            return data?.data?.series;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getDailyIncomeByBranch = createAsyncThunk('statistic/getDailyIncomeByBranch', async ({ values, isSameDay }) => {
    let data;
    try {
        const response = await axios.post(`/dashboard/dailyIncomeByBranch`, {
            ...values,
            startDate: isSameDay
                ? dayjs(values.startDate).hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm')
                : formatDateWithoutTime(values.startDate),
            endDate: isSameDay
                ? dayjs(values.endDate).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm')
                : formatDateWithoutTime(values.endDate),
        });
        data = await response.data;
        if (response.status === 200) {
            return data?.data?.series;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getPickupAndReturnRentable = createAsyncThunk('statistic/getPickupAndReturnRentable', async ({ values, isSameDay }) => {
    let data;
    try {
        const response = await axios.post(`/dashboard/pickupAndReturnRentable`, {
            ...values,
            startDate: isSameDay
                ? dayjs(values.startDate).hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm')
                : formatDateWithoutTime(values.startDate),
            endDate: isSameDay
                ? dayjs(values.endDate).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm')
                : formatDateWithoutTime(values.endDate),
        });
        data = await response.data;
        if (response.status === 200) {
            return data?.data?.series;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getPickupAndReturnRentableByDays = createAsyncThunk('statistic/getPickupAndReturnRentableByDays', async ({ values, isSameDay }) => {
    let data;
    try {
        const response = await axios.post(`/dashboard/pickupAndReturnRentableByDays`, {
            ...values,
            startDate: isSameDay
                ? dayjs(values.startDate).hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm')
                : formatDateWithoutTime(values.startDate),
            endDate: isSameDay
                ? dayjs(values.endDate).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm')
                : formatDateWithoutTime(values.endDate),
        });
        data = await response.data;
        if (response.status === 200) {
            return data?.data?.series;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getDashboardYears = createAsyncThunk('statistic/getDashboardYears', async () => {
    let data;
    try {
        const response = await axios.get(`/dashboard/years`);
        data = await response.data;
        if (response.status === 200) {
            return data?.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getNumberOfReservations = createAsyncThunk('statistic/getNumberOfReservations', async () => {
    let data;
    try {
        const response = await axios.get(`/dashboard/numberOfReservations`);
        data = await response.data;
        if (response.status === 200) {
            return data?.data?.nb;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getNumberOfRentable = createAsyncThunk('statistic/getNumberOfRentable', async () => {
    let data;
    try {
        const response = await axios.get(`/dashboard/numberOfRentable`);
        data = await response.data;
        if (response.status === 200) {
            return data?.data?.nb;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getNumberOfBranches = createAsyncThunk('statistic/getNumberOfBranches', async () => {
    let data;
    try {
        const response = await axios.get(`/dashboard/numberOfBranches`);
        data = await response.data;
        if (response.status === 200) {
            return data?.data?.nb;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getNumberOfClients = createAsyncThunk('statistic/getNumberOfClients', async () => {
    let data;
    try {
        const response = await axios.get(`/dashboard/numberOfClients`);
        data = await response.data;
        if (response.status === 200) {
            return data?.data?.nb;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getReservationClassMetrics = createAsyncThunk('statistic/getReservationClassMetrics', async ({ range, isSameDay }) => {
    let data;
    try {
        const response = await axios.post(`/dashboard/reservationClassMetrics`, {
            startDate: isSameDay
                ? dayjs(range.startDate).hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm')
                : formatDateWithoutTime(range.startDate),
            endDate: isSameDay
                ? dayjs(range.endDate).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm')
                : formatDateWithoutTime(range.endDate),
        });
        data = await response.data;
        if (response.status === 200) {
            return data?.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getDailyTasks = createAsyncThunk('statistic/getDailyTasks', async () => {
    let data;
    try {
        const response = await axios.get(`/dashboard/dailyTasks`);
        data = await response.data;
        if (response.status === 200) {
            return data?.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
const slice = createSlice({
    name: 'statistic',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAnnualExpense.pending, (state) => {
            state.AnnualExpenseStatus = 'loading';
        })
            .addCase(getAnnualExpense.fulfilled, (state, action) => {
            state.AnnualExpenseStatus = 'succeeded';
            state.AnnualExpense = action.payload;
        })
            .addCase(getAnnualExpense.rejected, (state, action) => {
            state.AnnualExpenseStatus = 'failed';
        })
            .addCase(getAnnualRevenue.pending, (state, action) => {
            state.AnnualRevenueStatus = 'loading';
        })
            .addCase(getAnnualRevenue.fulfilled, (state, action) => {
            state.AnnualRevenueStatus = 'succeeded';
            state.AnnualRevenue = action.payload;
        })
            .addCase(getAnnualRevenue.rejected, (state, action) => {
            state.AnnualRevenueStatus = 'failed';
        })
            .addCase(getAvailabilityReport.pending, (state, action) => {
            state.availabilityReportStatus = 'loading';
        })
            .addCase(getAvailabilityReport.fulfilled, (state, action) => {
            state.availabilityReportStatus = 'succeeded';
            state.availabilityReport = action.payload;
        })
            .addCase(getAvailabilityReport.rejected, (state, action) => {
            state.availabilityReportStatus = 'failed';
        })
            .addCase(getRentableIncomesAndOutcomes.pending, (state, action) => {
            state.incomesAndOutcomesStatus = 'loading';
        })
            .addCase(getRentableIncomesAndOutcomes.fulfilled, (state, action) => {
            state.incomesAndOutcomesStatus = 'succeeded';
            state.incomesAndOutcomes = action.payload;
        })
            .addCase(getRentableIncomesAndOutcomes.rejected, (state, action) => {
            state.incomesAndOutcomesStatus = 'failed';
        })
            .addCase(getRentableIncomesAndOutcomesByDays.pending, (state, action) => {
            state.incomesAndOutcomesStatus = 'loading';
        })
            .addCase(getRentableIncomesAndOutcomesByDays.fulfilled, (state, action) => {
            state.incomesAndOutcomesStatus = 'succeeded';
            state.incomesAndOutcomes = action.payload;
        })
            .addCase(getRentableIncomesAndOutcomesByDays.rejected, (state, action) => {
            state.incomesAndOutcomesStatus = 'failed';
        })
            .addCase(getReservationByPricingGroup.pending, (state, action) => {
            state.ReservationByPricingGroupStatus = 'loading';
        })
            .addCase(getReservationByPricingGroup.fulfilled, (state, action) => {
            state.ReservationByPricingGroupStatus = 'succeeded';
            state.ReservationByPricingGroup = action.payload;
        })
            .addCase(getReservationByPricingGroup.rejected, (state, action) => {
            state.ReservationByPricingGroupStatus = 'failed';
        })
            .addCase(getReservationByClass.pending, (state, action) => {
            state.ReservationByClassStatus = 'loading';
        })
            .addCase(getReservationByClass.fulfilled, (state, action) => {
            state.ReservationByClassStatus = 'succeeded';
            state.ReservationByClass = action.payload;
        })
            .addCase(getReservationByClass.rejected, (state, action) => {
            state.ReservationByClassStatus = 'failed';
        })
            .addCase(getDailyIncomeByBranch.pending, (state, action) => {
            state.DailyIncomeByBranchStatus = 'loading';
        })
            .addCase(getDailyIncomeByBranch.fulfilled, (state, action) => {
            state.DailyIncomeByBranchStatus = 'succeeded';
            state.DailyIncomeByBranch = action.payload;
        })
            .addCase(getDailyIncomeByBranch.rejected, (state, action) => {
            state.DailyIncomeByBranchStatus = 'failed';
        })
            .addCase(incomesAndOutcomesByYear.pending, (state, action) => {
            state.InAndOutByYearStatus = 'loading';
        })
            .addCase(incomesAndOutcomesByYear.fulfilled, (state, action) => {
            state.InAndOutByYearStatus = 'succeeded';
            state.InAndOutByYear = action.payload;
        })
            .addCase(incomesAndOutcomesByYear.rejected, (state, action) => {
            state.InAndOutByYearStatus = 'failed';
        })
            .addCase(getPickupAndReturnRentable.pending, (state, action) => {
            state.PickupAndReturnRentableStatus = 'loading';
        })
            .addCase(getPickupAndReturnRentable.fulfilled, (state, action) => {
            state.PickupAndReturnRentableStatus = 'succeeded';
            state.PickupAndReturnRentable = action.payload;
        })
            .addCase(getPickupAndReturnRentable.rejected, (state, action) => {
            state.PickupAndReturnRentableStatus = 'failed';
        })
            .addCase(getPickupAndReturnRentableByDays.pending, (state, action) => {
            state.PickupAndReturnRentableByDaysStatus = 'loading';
        })
            .addCase(getPickupAndReturnRentableByDays.fulfilled, (state, action) => {
            state.PickupAndReturnRentableByDaysStatus = 'succeeded';
            state.PickupAndReturnRentable = action.payload;
        })
            .addCase(getPickupAndReturnRentableByDays.rejected, (state, action) => {
            state.PickupAndReturnRentableByDaysStatus = 'failed';
        })
            .addCase(getDashboardYears.pending, (state, action) => {
            state.yearsStatus = 'loading';
        })
            .addCase(getDashboardYears.fulfilled, (state, action) => {
            state.yearsStatus = 'succeeded';
            state.years = action.payload;
        })
            .addCase(getDashboardYears.rejected, (state, action) => {
            state.yearsStatus = 'failed';
        })
            .addCase(getNumberOfReservations.pending, (state, action) => {
            state.totalReservationsStatus = 'loading';
        })
            .addCase(getNumberOfReservations.fulfilled, (state, action) => {
            state.totalReservationsStatus = 'succeeded';
            state.totalReservations = action.payload;
        })
            .addCase(getNumberOfReservations.rejected, (state, action) => {
            state.totalReservationsStatus = 'failed';
        })
            .addCase(getNumberOfRentable.pending, (state, action) => {
            state.totalRentalsStatus = 'loading';
        })
            .addCase(getNumberOfRentable.fulfilled, (state, action) => {
            state.totalRentalsStatus = 'succeeded';
            state.totalRentals = action.payload;
        })
            .addCase(getNumberOfRentable.rejected, (state, action) => {
            state.totalRentalsStatus = 'failed';
        })
            .addCase(getNumberOfBranches.pending, (state, action) => {
            state.totalBranchesStatus = 'loading';
        })
            .addCase(getNumberOfBranches.fulfilled, (state, action) => {
            state.totalBranchesStatus = 'succeeded';
            state.totalBranches = action.payload;
        })
            .addCase(getNumberOfBranches.rejected, (state, action) => {
            state.totalBranchesStatus = 'failed';
        })
            .addCase(getNumberOfClients.pending, (state, action) => {
            state.totalCustomersStatus = 'loading';
        })
            .addCase(getNumberOfClients.fulfilled, (state, action) => {
            state.totalCustomersStatus = 'succeeded';
            state.totalCustomers = action.payload;
        })
            .addCase(getNumberOfClients.rejected, (state, action) => {
            state.totalCustomersStatus = 'failed';
        })
            .addCase(getReservationClassMetrics.pending, (state, action) => {
            state.reservationClassMetricsStatus = 'loading';
        })
            .addCase(getReservationClassMetrics.fulfilled, (state, action) => {
            state.reservationClassMetricsStatus = 'succeeded';
            state.reservationClassMetrics = action.payload;
        })
            .addCase(getReservationClassMetrics.rejected, (state, action) => {
            state.reservationClassMetricsStatus = 'failed';
        })
            .addCase(getDailyTasks.pending, (state, action) => {
            state.dailyTasksStatus = 'loading';
        })
            .addCase(getDailyTasks.fulfilled, (state, action) => {
            state.dailyTasksStatus = 'succeeded';
            state.dailyTasks = action.payload;
        })
            .addCase(getDailyTasks.rejected, (state, action) => {
            state.dailyTasksStatus = 'failed';
        });
    },
});
export const reducer = slice.reducer;
export default slice;
